<template>
  <div class="flex flex-col lg:flex-row">
    <div class="flex flex-col w-full">
      <div
        class="border border-cs-light-grey dark:border-cs-medium-grey h-[60px] rounded-lg bg-transparent flex justify-between items-center p-[10px]"
      >
        <input
          v-model="inputValue"
          :placeholder="$t('landing.registerPlaceholder')"
          class="focus:outline-0 text-[#495057] w-full lg:w-[300px] mr-2 ml-5 text-sm bg-transparent"
        />
        <Button
          v-if="!showButtonOutside"
          :isLoading="isLoading"
          :onClick="registerUser"
          :text="$t('landing.registerButton')"
          class="!px-5 !py-[10px] !text-sm"
          variant="light"
          is-long
        />
      </div>
      <span v-if="registerError" class="mt-3 text-cs-red text-sm">{{ registerError }}</span>
      <div v-else class="mt-3">
        <Checkbox :changeHandler="updateAgreement" name="receive">
          <span :class="privacyError ? 'text-cs-red' : 'text-cs-medium-grey'" class="block w-full"
            >{{ $t('register.termsFirstPart') }}
            <CommonLink :title="$t('register.termsLink')" to="/privacy-notice">{{ $t('register.termsLink') }} </CommonLink>
            {{ $t('register.registerTerms2') }}
            <CommonLink :title="$t('register.registerTerms3')" to="/terms-of-use"
              >{{ $t('register.registerTerms3') }}
            </CommonLink>
          </span>
        </Checkbox>
      </div>
      <Button
        v-if="showButtonOutside"
        :isLoading="isLoading"
        :onClick="registerUser"
        :text="$t('landing.registerButton')"
        class="my-10"
        variant="light"
        :isLong="true"
      />
<!--      <LoginSocialButtons :isLogin="false" :isModal="isModal" />-->
    </div>
    <div v-if="showAppButtons" class="flex flex-row lg:flex-col justify-center mt-[50px] lg:mt-0 lg:ml-[100px]">
      <NuxtLink :to="config.public.APP_STORE_URL">
        <NuxtImg
          alt="App store"
          class="w-[170px] h-[52px] mr-5 lg:mr-0 lg:mb-5"
          format="webp"
          quality="100"
          sizes="sm:170px md:170px lg:170px"
          src="/app-store.png"
        />
      </NuxtLink>
      <NuxtLink :to="config.public.GOOGLE_PLAY_URL">
        <NuxtImg
          alt="Google play"
          class="w-[170px] h-[51px]"
          format="webp"
          quality="100"
          sizes="sm:170px md:170px lg:170px"
          src="/google-play.png"
        />
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {useUserRegister} from '~/composables/auth'
import {GTM_EVENT_EMAIL_REGISTRATION, GTM_EVENT_GUEST_MODAL_REGISTER_EMAIL, GTM_EVENT_TYPE_SUBMIT} from '~/constants/gtmEvent'
import {isValidEmail} from '~/util/email'
import {RegisterEvent} from '~/types/log.d'

interface ComponentProps {
  toggleModal: Function
  showAppButtons: boolean
  isModal?: boolean
  showButtonOutside?: boolean
}

const props = defineProps<ComponentProps>()

const config = useRuntimeConfig()
const {$eventTracker, $monitor} = useNuxtApp()

const i18n = useI18n()

const registerError = ref<string | null>(null)
const inputValue = ref<string | null>(null)
const checkboxAgreement = ref<boolean>(false)
const privacyError = ref<boolean>(false)
const isLoading = ref<boolean>(false)

const emit = defineEmits(['userAuthenticated'])

const registerUser = async () => {
  isLoading.value = true
  if (!checkboxAgreement.value) {
    privacyError.value = true
    isLoading.value = false
    return
  } else {
    privacyError.value = false
  }

  registerError.value = null
  const email: string = inputValue.value
  checkEmailIsValid(email)
  if (registerError.value !== null) {
    isLoading.value = false
    return
  }

  const {error} = await useUserRegister(email, null)
  if (error.value) {
    const statusCode = error.value?.statusCode
    if (statusCode === 409) {
      registerError.value = i18n.t('register.errorUsed')
    } else if (statusCode === 400) {
      registerError.value = i18n.t('register.errorInvalid')
    } else {
      registerError.value = i18n.t('common.error')
    }

    $monitor.criticalEvent(RegisterEvent, {
      success: false,
      code: statusCode,
      message: `User has problems with registration`,
      email
    })
  } else {
    if (props.isModal) {
      $eventTracker.triggerEvent(GTM_EVENT_GUEST_MODAL_REGISTER_EMAIL, GTM_EVENT_TYPE_SUBMIT)
    } else {
      $eventTracker.triggerEvent(GTM_EVENT_EMAIL_REGISTRATION, GTM_EVENT_TYPE_SUBMIT)
    }
    inputValue.value = ''
    props.toggleModal(true, email)
    emit('userAuthenticated')

    $monitor.criticalEvent(RegisterEvent, {
      success: true,
      code: 200,
      message: `User has successfully registered`,
      email
    })
  }
  isLoading.value = false
}

const checkEmailIsValid = (email: string) => {
  const isValid = isValidEmail(email)
  if (!isValid) {
    registerError.value = i18n.t('register.errorInvalid')
  }
}

const updateAgreement = (value: boolean) => {
  checkboxAgreement.value = value
}
</script>
